@import "@/styles/mixins";

:import("@/components/ui/Buttons/styles.module.scss") {
  buttons: buttons;
}

.footer {
  flex: 0 0 auto; width: 100%; max-width: calc(var(--content-maxwidth) + var(--gridunit) + var(--gridunit)); margin: 0 auto; padding: var(--gridunit); box-sizing: border-box;

  @include mobile {
    padding: var(--gridunit) var(--gridunit-small) var(--gridunit-small) var(--gridunit-small);
  }

  A {
      text-decoration: none; color: currentColor;

      &:hover { text-decoration: underline; }
    }

    P {
      margin-top: 1em;

      + P { margin-top: var(--gridunit-tiny); }
    }

  &__container {
    position: relative; @include clearfix;
    background-color: var(--footer-bg); color: var(--footer-color); border-radius: var(--radius-large);
  }

  &__copyright {
    padding: var(--gridunit-medium); flex: 0 0 auto; text-align: center; border-top: 1px solid currentColor;
  }

  &__columns {
    display: flex; flex-direction: row; align-items: flex-start; justify-content: space-between; gap: var(--gridunit);

    &__column {
      flex: 1 1 auto;

      &:first-child { max-width: 280px; }

      &:last-child { max-width: 280px; }
    }

    @include not-desktop {
      flex-direction: column; align-items: stretch;

      &__column + &__column {
        position: relative; max-width: none;

        &:before { content: ""; position: absolute; left: 0; top: 0; right: 0; height: 1px; background-color: currentColor; opacity: 0.6; }
      }
    }
  }

  &__list {
    margin: var(--gridunit) 0 0 0;

    @include desktop {
      display: flex; flex-direction: column; gap: var(--gridunit-tiny);
    }
    @include not-desktop {
      display: flex; flex-direction: row; gap: var(--gridunit-small); flex-wrap: wrap;

      > LI { flex: 1 1 50%; }
    }
  }

  &__logo {
    flex: 0 0 auto; display: flex; margin-top: var(--gridunit) !important;

    &:not(:first-child) { margin-top: 2em !important; }

    A { display: flex; }

    IMG { width: auto; height: 36px; max-width: 180px; }
  }

  &__scroll-to-top {
    position: absolute; right: var(--gridunit); top: 0; z-index: 1; transform: translate(0, -50%);
  }

  &__areas {
    padding: var(--gridunit); display: grid; grid-template-areas: "contact links social" "contact links booking"; grid-template-columns: 280px auto 280px; grid-auto-rows: auto; column-gap: var(--gridunit);

    @include mobile {
      grid-template-areas: "booking" "contact" "links" "social"; grid-template-columns:  1fr; row-gap: var(--gridunit);

      &__contact { border-top: 1px solid var(--color-neutral-400); }

      &__links { border-top: 1px solid var(--color-neutral-400); }

      &__social {
        border-top: 1px solid var(--color-neutral-400);

        UL { align-items: center !important; justify-content: space-evenly !important; }
      }
    }

    @include tablet {
      grid-template-areas: "booking booking" "contact links" " social links"; grid-template-columns:  1fr 1fr;

      &__booking { border-bottom: 1px solid var(--color-neutral-400); padding-bottom: var(--gridunit); }
    }

    &__contact { grid-area: contact ; }

    &__links { grid-area: links; }

    &__social {
      grid-area: social;

      .buttons { gap: var(--gridunit); }
    }

    &__booking { grid-area: booking; }
  }

  @media print { display: none !important; }
}
