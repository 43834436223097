@import "@/styles/mixins";

:import("@/components/ui/Block/styles.module.scss") {
  block: block;
}

.body {
    flex: 1 0 auto; min-height: calc(100vh - 120px); background-color: var(--site-bg);
    display: flex; flex-direction: column;

    > * {
      flex-grow: 0;

      &:last-child { flex-grow: 1; }
    }
}
