@import "@/styles/mixins";

.sticky {
  position: sticky; left: 0; right: 0; bottom: 0; z-index: 10; pointer-events: none;

  > * { pointer-events: auto; }

  &__mock {
    position: fixed; z-index: -1; left: 0; right: 0; bottom: 0; background-color: var(--color-white); height: 50vh; pointer-events: none;
  }
}
