@import "@/styles/mixins";

:import("@/components/ui/Buttons/styles.module.scss") {
  buttons: buttons;
}


.menu-mobile {
  A { text-decoration: none; }

  .buttons { margin: var(--gridunit-small) 0 0 0; }

  &__tools {
    margin: 0; display: flex; flex-direction: row; text-align: center; background-color: var(--color-primary-850); color: var(--color-neutral-300); padding: 13px; box-sizing: border-box;

    > LI {
      flex: 1 1 0; display: flex;

      + LI {
        padding-left: 1px; position: relative;

        &:before { content: ""; position: absolute; left: 0; top: 50%; width: 1px; height: 24px; transform: translate(0, -50%); background-color: currentColor; }
      }

      > * {
        flex: 1 1 auto; display: flex; align-items: center; justify-content: center; width: 100%;
      }

    }

    * { text-transform: uppercase !important; }

    svg { width: 24px !important; height: 24px !important; }

    @include desktop { display: none; }

    @include tablet { max-width: 360px; }
  }

  &__links {
    flex: 0 0 auto; margin: var(--gridunit-small) 0 0 0; font-weight: var(--bold); font-size: 18px;

    A {
      text-decoration: none; color: currentColor; position: relative; display: inline-block; padding: var(--gridunit-tiny) var(--gridunit-small);

      &:hover { text-decoration: underline; }

      &:focus { text-decoration: underline; outline: none; }
    }

    &__sub { font-weight: 400; padding: var(--gridunit-small); }
  }
}
